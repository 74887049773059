/**
 * Adds zero padding to the beginning of the received string and returns a
 * string that has a maximum length of 2
 *
 * @param {string|number} value - the value to zero pad
 * @returns {string} - zero padded string, e.g. `02`
 */
export function padZero(value) {
  return ('0' + value).substr(-2);
}
