import { interpolate } from './js/interpolate';
import { padZero } from './js/pad-zero';

const body = document.getElementsByTagName('body')[0];
const display = document.getElementById('display-clock');
const code = document.getElementById('display-code');
let last = 0;

// /**
//  * FIXME is this even used?
//  * @param n
//  * @param low
//  * @param high
//  * @returns {number}
//  */
// function constrain(n, low, high) {
//   return Math.max(Math.min(n, high), low);
// }

// function lerp(number, low, high){
//  const lerp = (x, y, a) => x * (1 - a) + y * a;
//   return low * (1 - number) + high * number;
//   // return 15;
// }

/**
 * Generates an RGB string to be used in HTML of Canvas
 *
 * @param {array} colour - the colour array
 * @returns {string} - the RGB string, e.g. rgb(15, 12, 100)
 */
function rgbString(colour) {
  return `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`;
}

/**
 * Generates a Hexadecimal string, e.g. `#33ee00`
 *
 * @param {string[]} colour - the colour array [r, g, b]
 * @returns {string} - string representing the colour e.g. `#33ee00`
 */
function hexString(colour) {
  const [r, g, b] = colour;
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

/**
 * Returns a string representing the HSL colour, e.g. hsl(40, 10%, 8%)
 * @param {string[]} colour - the colour array
 * @returns {string} - the HSL colour string e.g. hsl(40, 10%, 8)
 */
function hslString(colour) {
  const [h, s, l] = colour;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

/**
 * Converts an RGB colour array to a Hexadecimal string representation
 * @param colour
 * @returns {string}
 */
function rgbToHex(colour) {
  const [r, g, b] = colour;
  return [
    r.toString(16),
    g.toString(16),
    b.toString(16)
  ];
}

/**
 * Converts RGB colour space to HSL colour space
 *
 * @param colour - the colour array to convert
 * @returns {(number|number)[]}
 */
function rgbToHsl(colour) {
  let [r, g, b] = colour;

  r /= 255, g /= 255, b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let l = (max + min) / 2;
  let h;
  let s;

  if(max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch(max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }
  return [h, s, l];
}

function rgbToHsl2(colour) {
  let [r, g, b] = colour;

  // Make r, g, and b fractions of 1
  r /= 255;
  g /= 255;
  b /= 255;

  // Find greatest and smallest channel values
  const channelMinimum = Math.min(r, g, b);
  const channelMaximum = Math.max(r, g, b);
  const delta = channelMaximum - channelMinimum;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  if(delta === 0) {
    // No difference
    h = 0;
  } else if(channelMaximum === r) {
    // Red is max
    h = ((g - b) / delta) % 6;
  } else if(channelMaximum === g) {
    // Green is max
    h = (b - r) / delta + 2;
  } else {
    // Blue is max
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  // Make negative hues positive behind 360°
  if(h < 0) {
    h += 360;
  }

  // Calculate lightness
  l = (channelMaximum + channelMinimum) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Multiply l and s by 100
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
}

/**
 * Rotate the HSL colour by a certain amount of degrees
 *
 * @param {string[]} hsl - the HSL colour as an array [h, s, l]
 * @param {number} degrees - number of degrees to rotate the colour wheel by
 * @returns {number[]} - the new HSL colour as an array `[h, s, l]`
 */
function rotateHsl(hsl, degrees) {
  const [h, s, l] = hsl;

  return [(360 + h + degrees) % 360, s, l];
}

// hextToRgb: value = parseInt(hex, 16);

/**
 * Returns a base64 encoded data url that can be used for the favicon
 *
 * @param colour - the rgb colour code to use as background
 * @returns {string} - the
 */
function getBase64Image(colour) {
  // return btoa('<svg height="16" width="16">' +
  //   '<circle cx="8" cy="8" r="8" fill="' + rgbString(colour) + '" /></svg>');
  // <svg height="16" width="16">
  return btoa(
    `<svg xmlns="http://w3.org/2000/svg" viewBox="0 0 100 100">
            <circle cx="8" cy="8" r="8" fill="${rgbString(colour)}" />
          </svg>`
  );

  // // Create an empty canvas element
  // const canvas = document.createElement("canvas");
  // const width = 16;
  // const height = 16;
  // canvas.width = width;
  // canvas.height = height;
  //
  // const context = canvas.getContext('2d');
  // context.fillStyle = rgbString(colour);
  // context.fillRect(0, 0, width, height);
  // // Copy transparent favicon png to  canvas
  // // context.drawImage(img, 0, 0);
  //
  // const dataURL = canvas.toDataURL('image/png');
  //
  // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

// const favicon = document.querySelector('[type="image/x-icon"]');
// favicon.href = "__some_url__";
// data url?

/**
 * Gets called every second, updates the time, background color and
 * foreground colour
 *
 * @param timer - milliseconds passed since invoking requestAnimationFrame()
 */
function clock(timer) {
  // only run this function once every second
  if(timer - last >= 1000) {
    last = timer;

    const root = document.documentElement;
    const debugTime = 0; //12 * 60 * 60 * 1000;
    const time = new Date(Date.now() + debugTime);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    // const month = time.getMonth();
    // const date = time.getDate();
    // const year = time.getFullYear();
    const hour = hours > 12 ? hours - 12 : hours;
    const maxHour = hours > 12 ? 11 : 12;
    const back = [
      interpolate(hour, 0, maxHour, hours > 12), // greater than
      interpolate(minutes, 0, 59, hours >= 12),  // greater or equal than
      interpolate(seconds, 0, 59, hours >= 12)   // greater or equal than
    ];
    const front = [
      255 - back[0],
      255 - back[1],
      255 - back[2]
    ];
    const hsl = rgbToHsl(back);
    const hslLeft = rotateHsl(hsl, -30);
    const hslRight = rotateHsl(hsl, 30);

    // console.log('tick... tock...', time.getSeconds(), time.getMilliseconds());
    // month = ''+month
    // console.log('front', rgbString(front));
    display.style.color = rgbString(front);
    display.textContent = `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;

    code.style.color = rgbString(front);
    root.style.setProperty('--font-color', rgbString(front));
    code.textContent = hexString(rgbToHex(back));

    // root.style.setProperty('--gradient-main', hslString(hsl));
    // root.style.setProperty('--gradient-left', hslString(hslLeft));
    // root.style.setProperty('--gradient-right', hslString(hslRight));

    root.style.setProperty('--gradient-main', rgbString(back));

    // display.textContent = `${padZero(date)}-${padZero(month)}-${year}`;
    // body.style.backgroundColor = rgbString(back);

    /*
     <meta name="theme-color" content="#000000"/>
     <meta name="msapplication-navbutton-color" content="#000000"/>
     <meta name="apple-mobile-web-app-status-bar-style" content="#000000"/>

     */
  }

  requestAnimationFrame(clock);
}

requestAnimationFrame(clock);

module.exports = interpolate;
