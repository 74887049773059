/**
 * Interpolates the supplied number between 0 and 255 (included)
 *
 * @see https://www.trysmudford.com/blog/linear-interpolation-functions/
 * @param {string|number} value - the number to interpolate
 * @param {number} low - lowest value `number` can have
 * @param {number} high - highest value `number` can have
 * @returns {number} - the interpolated number, e.g. 201
 * @param {boolean} [inverse] - inverse calculation; default false
 * @returns {*}
 */
export function interpolate(value, low, high, inverse = false) {
  const minimum = inverse ? 255 : 0;
  const maximum = inverse ? 0 : 255;
  const rounding = inverse ? 'floor': 'ceil';
  return Math[rounding](
    (value - low) / (high - low) * (maximum - minimum) + minimum
  );
}

// additional functions
// const lerp = (x, y, a) => x * (1 - a) + y * a;
// const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
// const invlerp = (x, y, a) => clamp((a - x) / (y - x));
// const range = (x1, y1, x2, y2, a) => lerp(x2, y2, invlerp(x1, y1, a));
